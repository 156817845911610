<template>
    <div class="bg">
        <div class="user-box">
            <img :src="rImg(data.head_portrait)" :onerror="txImg" class="tx" alt="" />
            <div class="user">
                <h3 class="name">{{ data.name }}</h3>
                <p class="u-id phone" @click="copyTxt(data.id)">热浪号：{{data.id}} <i class="el-icon-document-copy"></i></p>
                <div class="num-box">
                    <span class="num"><span class="n" v-html="returnNum(data.friend)"></span>好友</span>
                    <span class="num"><span class="n" v-html="returnNum(data.following)"></span>关注</span>
                    <span class="num" style="margin-right:0"><span class="n" v-html="returnNum(data.attention)"></span>粉丝</span>
                    <span class="num u-id pc" @click="copyTxt(data.id)">热浪号：{{data.id}} <i class="el-icon-document-copy"></i></span>
                </div>
            </div>
        </div>
        <ul class="data-box">
            <li class="list">
                <p class="num">{{artInfo.view_num}}</p>
                <p class="title"><i class="el-icon-view"></i> 观看</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.video_time}}</p>
                <p class="title"><i class="el-icon-time"></i> 观看总时长</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.thumbsup}}</p>
                <p class="title"><i class="el-icon-thumb"></i> 点赞</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.collect}}</p>
                <p class="title"><i class="el-icon-star-off"></i> 收藏</p>
            </li>
            <li class="list">
                <p class="num">{{artInfo.comment}}</p>
                <p class="title"><i class="el-icon-chat-round"></i> 评论</p>
            </li>
        </ul>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {},
        data() {
            return {
                data: {},
                artInfo: {},
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.getData();
            this.getArtData();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            async getData() {
                let res = await this.$y_getUser();
                if (res) {
                    this.data = res;
                }
            },
            async getArtData() {
                let {
                    code,
                    data
                } = await this.$y_ajax("api/nihao/data");
                if (code == 200) {
                    this.artInfo = data;
                }
            },
            copyTxt(txt) {
                navigator.clipboard.writeText(txt).then(() => {
                    this.$y_msg("复制成功");
                });
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        },
    };
</script>

<style lang="less" scoped>
    .user-box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        display: flex;
        align-items: center;

        .tx {
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }

        .user {
            flex: 1;
        }

        .name {
            font-size: 25px;
            font-weight: normal;
        }

        .num-box {
            margin-top: 10px;
            color: #aaa;

            .num {
                margin-right: 30px;

                .n {
                    color: #333;
                    margin-right: 5px;
                    font-size: 18px;
                }
            }

            .u-id {
                border-left: 1px solid #eee;
                padding-left: 30px;
                margin-left: 30px;
            }
        }

        .u-id {
            color: #aaa;
            font-size: 0.6rem;
        }
    }

    .data-box {
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

        .list {
            border: 1px solid #eee;
            border-radius: 10px;
            width: 30%;
            padding: 30px;
            box-sizing: border-box;
            margin-right: 5%;
            margin-bottom: 20px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .title {
                color: #999;
            }

            .num {
                font-size: 35px;
            }
        }
    }

    .pc {
        display: inline-block;
    }

    .phone {
        display: none;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .data-box {
            .list {
                width: 100%;
                margin-right: 0;
            }
        }

        .pc {
            display: none;
        }

        .phone {
            display: inline-block;
        }
    }
</style>